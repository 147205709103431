import React, { useState, useCallback, useRef, useEffect } from 'react';
import axios from 'axios';
import AudioRecorder2 from './AudioRecorder2';
import ResultsDisplay from './ResultsDisplay';
import FeatureSelector from './FeatureSelector';
import '../App.css';
import History from './History';
import { useApi } from '../middleware/ApiMiddleware'; // Assuming you are using this for API calls

const BASE_URL = process.env.REACT_APP_BASE_URL || "https://3.6.69.216/";


const MainComponent = ({ client_id }) => {
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [stopPlayingAudio, setStopPlayingAudio] = useState(false);
  const [iserror, setIserror] = useState(false);
  const [isplaying, setIsPlaying] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);  // State to store chat history
  const [association, setAssociation] = useState(null); // Track the association state
  const abortControllerRef = useRef(null);
  const api = useApi();   // Ref to store the AbortController

  // Fetch initial chat history from the history API
  const fetchInitialHistory = useCallback(async () => {
    try {
      const response = await api.get('api/process/history/');
      if (response.data.success) {
        setChatHistory(response.data.data.chat); // Set the initial chat history
      } else {
        console.error("Failed to fetch chat history");
      }
    } catch (error) {
      console.error('Error fetching chat history:', error);
    }
  }, []);

  // Fetch chat history on component mount
  useEffect(() => {
    fetchInitialHistory();
  }, [fetchInitialHistory]);

  // Set association state from localStorage when component mounts
  useEffect(() => {
    const storedAssociation = localStorage.getItem("association");
    if (storedAssociation) {
      // console.log("Association found in localStorage:", storedAssociation);
      try {
        const parsedAssociation = JSON.parse(storedAssociation);
        setAssociation(parsedAssociation);
      } catch (error) {
        console.error("Error parsing association from localStorage:", error);
      }
    } else {
      // console.log("No association found in localStorage.");
    }
  }, []);

  // Update localStorage whenever association changes
  useEffect(() => {
    if (association !== null) {
      // console.log("Saving association to localStorage:", association);
      // localStorage.setItem("association", JSON.stringify(association));
    }
  }, [association]);

  const processAudio = useCallback(async (blob) => {
    setLoading(true);
    const formData = new FormData();
    const id = JSON.parse(localStorage.getItem("association"))
    formData.append('file', blob, 'recording.wav');
    formData.append('query', 'recording.wav');
    formData.append('association_id', id['id']); // Use the current association state
    abortControllerRef.current = new AbortController(); // Create a new AbortController

    try {
      const response = await api.post('api/process/alaap/v1_1/', formData, {
        signal: abortControllerRef.current.signal, 
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}`, 
        }
      });

      const links = [];
      if (response.data.data.TTS) {
        for (let i = 1; i <= response.data.data.TTS.count; i++) {
          let link = `${BASE_URL}static_src/${response.data.data.TTS.filename}${i}.wav?nocache=${new Date().getTime()}`;
          links.push(link);
        }
      }

      const print_data = {
        ttsText: response.data.data.ASR,
        error: response.data.data.error,
        translatedQuery: response.data.data.TRA,
        llmText: response.data.data.LLM,
        translatedAnswer: response.data.data.TRA2,
        sttAudioUrl: response.data.data.TTS,
        hostURL: response.data.data.host,
        links: links,
      };

      setResults(print_data);

      // Append the new conversation to chatHistory
      const newHistoryEntry = {
        user: response.data.data.ASR !== "ERROR" ? response.data.data.ASR : "recording.wav",  // User input from ASR
        assistant: response.data.data.LLM ? response.data.data.LLM : "ERROR"  // Assistant's response from LLM
      };
      setChatHistory((prevHistory) => [newHistoryEntry, ...prevHistory]);  // Update chat history with the new entry

    } catch (error) {
      if (axios.isCancel(error)) {
        // Request was canceled
      } else {
        console.error('Error processing audio:', error);
      }
    } finally {
      setLoading(false);
      setStopPlayingAudio(false);  // Allow new recordings once processing is complete
    }
  }, [association]); // Dependency on association

  const handleRecordingComplete = useCallback((blob) => {
    setResults(null);  // Reset the results
    setStopPlayingAudio(true);  // Stop all playing audio
    processAudio(blob);
  }, [processAudio]);

  const handleReRecord = useCallback(() => {
    setStopPlayingAudio(true);  // Reset the stopPlayingAudio state when re-recording
    setIserror(false);
  }, []);

  const handleError = useCallback(() => {
    setIserror(true);
    // Reset iserror to false after 5 seconds
    setTimeout(() => {
      setIserror(false);
    }, 5000);
  }, []);

  const handlestartplaying = useCallback(() => {
    setIsPlaying(true);
  }, []);

  const handelstopplaying = useCallback(() => {
    setIsPlaying(false);
  }, []);

  // Function to abort the API request
  const handleAbortRequest = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();  // Abort the request
      setLoading(false);  // Set loading to false after aborting
    }
  };

  // Handle feature selection and update the association state
  const handleFeatureSelection = (selectedAssociation) => {
    // console.log("Selected association:", selectedAssociation);
    setAssociation(selectedAssociation);
  };

  return (
    <div className="app">
      {/* Pass the association prop to FeatureSelector */}
      <FeatureSelector onFeatureSelect={handleFeatureSelection} />

      {/* Audio Recorder Component */}
      <AudioRecorder2 
        onRecordingComplete={handleRecordingComplete} 
        onReRecord={handleReRecord}  // Pass the new function to AudioRecorder
        disabled={loading}
        isplaying={isplaying}
        iserror={iserror}
        error={handleError}
        stopRequest={handleAbortRequest} 
        association={association} // Pass the association to AudioRecorder
      />

      {/* Display results when available */}
      {results && (
        <ResultsDisplay 
          results={results ? results : ""}
          stopPlayingAudio={stopPlayingAudio}
          handleMainError={handleError}
          handlestartplaying={handlestartplaying}
          handelstopplaying={handelstopplaying}
        />
      )}

      {/* Pass chatHistory to History component */}
      <History 
        key={chatHistory.length}  // This key will force re-render on each new entry
        chatHistory={chatHistory} 
        latestIndex={0} 
      />
    </div>
  );
};

export default MainComponent;
