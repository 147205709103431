import React, { useState, useEffect } from 'react';
import { GoogleGenerativeAI } from "@google/generative-ai";

const List = () => {
  const [transcript, setTranscript] = useState('');
  const [response, setResponse] = useState('');
  const [recognition, setRecognition] = useState(null);
  const genAI = new GoogleGenerativeAI("AIzaSyDmVkUrKZMOfAqy_hq1A1Qv7mrbNzYBdL8"); // Consider moving this to an environment variable.

  useEffect(() => {
    // Initialize SpeechRecognition
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (SpeechRecognition) {
      const recog = new SpeechRecognition();
      recog.continuous = true;
      recog.lang = 'en';

      recog.onresult = (event) => {
        const current = event.resultIndex;
        const newTranscript = event.results[current][0].transcript;
        setTranscript((prev) => prev + ' ' + newTranscript);
      };

      recog.onerror = (event) => {
        console.error('Speech recognition error:', event.error);
      };

      setRecognition(recog);

      return () => {
        recog.stop();
        recog.onresult = null; // Cleanup on unmount
        recog.onerror = null; // Cleanup on unmount
      };
    } else {
      console.error('SpeechRecognition API is not supported in this browser');
    }
  }, []);

  const startListening = () => recognition && recognition.start();
  const stopListening = () => recognition && recognition.stop();

  const handleSubmit = async () => {
    if (transcript.trim()) {
      const prompt = `
      assume the below list are object of a python model named "List" with respected field write the query to search the table with the appropriate parameters givien in the query 
      
      Here is the list:
          
      [
          {"name": "Fuzzy Socks", "color": "Blue", "size": "Small"},
          {"name": "Crystal Ball", "color": "Purple", "size": "Medium"},
          {"name": "Vintage Record Player", "color": "Brown", "size": "Large"},
          {"name": "Neon Sign", "color": "Pink", "size": "Medium"},
          {"name": "Antique Clock", "color": "Gold", "size": "Small"},
          {"name": "Canvas Painting", "color": "Colorful", "size": "Large"},
          {"name": "Leather Journal", "color": "Brown", "size": "Small"},
          {"name": "Ceramic Vase", "color": "White", "size": "Medium"}
      ]
      
      Query example:
      Find all items where the "key1" is "value1" and the "key2" is "value2".
      
      Expected Response Format:
      
      OrgUser.objects.filter(key1=value1, key2=value2)
      
      Now perform the query:
      ${transcript}`
      try {
        const model = genAI.getGenerativeModel({ model: "gemini-pro" });
        const result = await model.generateContent(prompt);

        // Check if the response is structured correctly
        if (result && result.response) {
          setResponse(result.response.text || "No text found in response.");
        } else {
          setResponse("Unexpected response format.");
        }
      } catch (error) {
        console.error('Error fetching response from Ola Kutrim API:', error);
      }
    }
  };

  if (!recognition) {
    return <p>Your browser does not support the SpeechRecognition API.</p>;
  }

  return (
    <div>
      <button onClick={startListening}>Start Listening (Hindi)</button>
      <button onClick={stopListening}>Stop Listening</button>
      <button onClick={handleSubmit}>Submit to Gemini</button>
      <p>Transcript: {transcript}</p>
      <p>Response: {response}</p>
    </div>
  );
};

export default List;