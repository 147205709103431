import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../middleware/ApiMiddleware'; 
import Footer from './Footer';

const LoginForm = ({ changeComponent }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const api = useApi();

  // Check if token is already in localStorage and redirect if it is
  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      changeComponent('home');
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    const loginData = {
      email: email,
      password: password,
    };

    try {
      const response = await api.post('api/process/login/v2/', loginData, { withCredentials: true });

      if (response.data.success === true) {
        // console.log('Login successful:', response);
        localStorage.setItem('accessToken', response.data.data.data.token);
        localStorage.setItem('user', JSON.stringify(response.data.data.data)); // Store user data in localStorage
        const cookie = response.headers['set-cookie'] || document.cookie;
        if (cookie) {
          const decodedCookie = atob(cookie);
          localStorage.setItem('decodedCookie', decodedCookie);
        }
        changeComponent('Home');
      } else {
        setError('Login failed. Please try again.');
      }
    } catch (error) {
      setError('An error occurred. Please try again later.');
      console.error('Login error:', error);
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    // console.log("change");
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className="sign-container">
        <div className="form-box">
          <img 
            src={require('../assets/cplogo.jpg')} 
            alt="Alaap Logo" 
            className="form-image"
          />
          <p>Login to your account</p>
          <form onSubmit={handleSubmit}>
            {/* Email Field */}
            <div className="form-floating-group">
              <input
                type="email"
                id="email"
                name="email"
                className="form-floating-input"
                placeholder=""
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <label htmlFor="email" className="form-floating-label">
                Email Address
              </label>
            </div>

            {/* Password Field */}
            <div className="form-floating-group password-group">
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                name="password"
                className="form-floating-input"
                placeholder=""
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <label htmlFor="password" className="form-floating-label">
                Password
              </label>
              <span 
                className="password-toggle-icon" 
                onClick={togglePasswordVisibility}
              >
                <i className={showPassword ? "fas fa-eye" : "fas fa-eye-slash"}></i>
              </span>
            </div>

            {/* Error Message */}
            {error && <p style={{ color: 'red' }}>{error}</p>}

            {/* Submit Button */}
            <button type="submit" disabled={loading}>
              {loading ? 'Logging in...' : 'Login'}
            </button>
          </form>

          <p>Don't have a username and password?</p>
          <button onClick={() => changeComponent('signup')}>
            Create an account
          </button>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
