import React, { useState, useRef, useCallback, useEffect } from 'react';

const AudioRecorder = ({ onRecordingComplete, onReRecord, disabled, error, isplaying,iserror, stopRequest }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const [time, setTime] = useState(0); // Timer state
  const [isMobile, setIsMobile] = useState(window.innerWidth < 746); // Track if it's mobile
  const mediaRecorder = useRef(null);
  const audioChunks = useRef([]);
  const timerRef = useRef(null); // Ref for the timer
  const audioRef = useRef(null); // Ref for the audio element
  const maxRecordingTime = 15;
  const currentTimeRef = useRef(0); // Set the max recording time to 15 seconds
  let soundDetected = false; // flag to track if sound has been detected

  // Detect screen size and update `isMobile` state
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 746);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const startRecording = useCallback(async () => {
    currentTimeRef.current = 0;
    audioChunks.current = [];
    setTime(0); // Reset timer

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorder.current = new MediaRecorder(stream);

      mediaRecorder.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks.current.push(event.data);
        }
      };
      mediaRecorder.current.onstop = () => {
        const audioBlob = new Blob(audioChunks.current, { type: 'audio/mp3' });
        const audioUrl = URL.createObjectURL(audioBlob);
        setAudioUrl(null);
        if (soundDetected) {
          onRecordingComplete(audioBlob);
        }
        clearInterval(timerRef.current); // Stop the timer
      };
      mediaRecorder.current.start();
    } catch (error) {
      console.error('Error accessing the microphone:', error);
      alert('Error accessing the microphone. Please ensure you have given permission.');
    }
  }, [onRecordingComplete]);

  const stopRecording = useCallback(() => {
    if (mediaRecorder.current && mediaRecorder.current.state !== 'inactive') {
      mediaRecorder.current.stop();
      setIsRecording(false);
    }
    clearInterval(timerRef.current); // Stop the timer
    setTime(0);
  }, []);

  useEffect(() => {
    if (audioUrl) {
      const audio = new Audio(audioUrl);
      audioRef.current = audio;
      audio.play();
    }
    return () => {
      if (audioUrl) {
        URL.revokeObjectURL(audioUrl);
      }
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, [audioUrl]);

  const detect = useCallback(async () => {
    soundDetected = false;
    setIsRecording(true);
    if (onReRecord) {
      onReRecord(); // Stop any playing audio when recording starts
    }

    function detectSilence(
      stream,
      onSoundEnd = () => {},
      onSoundStart = () => {},
      silence_delay = 500,
      min_decibels = -70,
      initial_timeout = 1000,
      time_limit = 15000
    ) {
      const ctx = new AudioContext();
      const analyser = ctx.createAnalyser();
      const streamNode = ctx.createMediaStreamSource(stream);
      streamNode.connect(analyser);
      analyser.minDecibels = min_decibels;
      const data = new Uint8Array(analyser.frequencyBinCount); // will hold our data
      let silence_start = performance.now();
      let speak_start = performance.now();
      let triggered = true; // trigger only once per silence event
      let isLooping = true; // control the loop state
      onSoundStart();

      function loop(time) {
        if (!isLooping) return; // Stop the loop if not looping

        requestAnimationFrame(loop); // we'll loop every 60th of a second to check
        analyser.getByteFrequencyData(data); // get current data

        if (data.some((v) => v)) {
          soundDetected = true; // mark sound as detected
          if (triggered) {
            triggered = false;
            speak_start = time;
          }
          silence_start = time; // set it to now
        }

        if ((!triggered && time - silence_start > silence_delay) || time - speak_start > time_limit) {
          onSoundEnd();
          triggered = true;
          isLooping = false; // Stop the loop after triggering onSoundEnd
        }
      }

      // Start loop
      loop();

      // Check if sound is detected within the first 1000ms
      setTimeout(() => {
        if (!soundDetected) {
          setIsRecording(false);
          error();
          onSoundEnd(); // You can also trigger any callback if needed
          isLooping = false; // Stop the loop
        }
      }, initial_timeout);
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      detectSilence(stream, stopRecording, startRecording, 1500, -50, 2000, 15000);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const stop = useCallback(() => {
    if (onReRecord) {
      onReRecord(); // Stop any playing audio when recording starts
    }
  }, [onReRecord]);

  const abort = useCallback(() => {
    if (stopRequest) {
      stopRequest(); // Stop any playing audio when recording starts
    }
  }, [stopRequest]);

  return (
    <div className="audio-recorder">
      <div className="head_audio">{isRecording ? 'Tap to restart' : disabled ? 'Tap to restart' : isplaying? "Tap to restart": iserror?"Tap to restart": ''}</div>

      {!audioUrl && (
        <button
          onMouseDown={!isMobile ? (isplaying ? stop : disabled ? abort :iserror?stop: detect) : null}
          onTouchStart={isplaying ? stop : disabled ? abort :iserror?stop: detect}
          className={isRecording ? 'isrecording_btn' : disabled ? 'disabled_btn' : isplaying ? 'isplaying' :iserror?"error_button": 'recording_btn'}
        >
          {isRecording ? 'Recording' : disabled ? 'Processing' : isplaying ? 'Playing response': iserror?"There was an error": 'Start Recording'}
        </button>
      )}
    </div>
  );
};

export default AudioRecorder;
            