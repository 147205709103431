import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApi } from "../middleware/ApiMiddleware";
import { NavLink } from "react-router-dom";

const VerticalNavBar = ({ changeComponent, activeComponent }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [orgName, setOrgName] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");
  const api = useApi();

  useEffect(() => {
    const userData = localStorage.getItem("user");
    if (userData) {
      const parsedUser = JSON.parse(userData);
      setOrgName(parsedUser.org_name || "Organization");
      setEmail(parsedUser.email || "User");
      setStatus(parsedUser.status || "USER");
    }
  }, []);

  const handleClick = (component) => {
    changeComponent(component);
    setIsOpen(false);
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    setIsSettingsOpen(false)
  };

  const toggleSettingsDropdown = () => {
    setIsSettingsOpen(!isSettingsOpen);
  };

  const handleLogout = async () => {
    try {
      await api.get("api/process/logout/v2/");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");
      localStorage.removeItem("association");
      changeComponent("login");
    } catch (error) {
      console.error("Error Logging Out:", error);
    }
  };

  return (
    <div>
      {/* Hamburger Icon */}
      <button
        className="btn"
        type="button"
        onClick={toggleMenu}
        style={{
          position: "absolute",
          top: "15px",
          left: "15px",
          zIndex: 1050,
          background: "none",
          border: "none",
        }}
      >
        <i
          className="fa fa-bars"
          style={{ fontSize: "1.5rem", color: "#0B5ED7" }}
        ></i>
      </button>

      {/* Sidebar */}
      <div
        className={`bg-dark text-light ${
          isOpen ? "d-block" : "d-none"
        } position-fixed`}
        style={{
          width: "200px",
          height: "100vh",
          top: 0,
          left: 0,
          zIndex: 1049,
          overflowY: "auto",
        }}
      >
        <div className="d-flex justify-content-end p-2">
          <button
            className="btn-close btn-close-white"
            onClick={toggleMenu}
            aria-label="Close"
          ></button>
        </div>

        <nav className="nav flex-column px-3">
          <br></br>
          <br></br>
          <button
            className={`nav-link text-light text-start ${
              activeComponent === "Dashboard" ? "active" : ""
            }`}
            onClick={() => handleClick("Dashboard")}
          >
            <i className="fa-solid fa-house"></i> Dashboard
          </button>

          
          <button
            className={`nav-link text-light text-start ${
              activeComponent === "File" ? "active" : ""
            }`}
            onClick={() => handleClick("File")}
          >
            <i className="fa-solid fa-file"></i> File
          </button> 

        {/* 
          <div className={`dropdown ${isSettingsOpen ? "open" : ""}`}>
            <div className="dropdown-header" onClick={toggleSettingsDropdown}>
              <i className="fas fa-cog nav-link text-light text-start"></i>{" "}
              Settings
              <span
                className={`dropdown-icon ${isSettingsOpen ? "rotate" : ""}`}
              >
                &#9660;
              </span>
            </div>

            {isSettingsOpen && (
              <div className="dropdown-content">
                <button
                  onClick={() => handleClick("Config")}
                  className={activeComponent === "Config" ? "active-link" : ""}
                >
                  <i className="fa-solid fa-gear"></i> Config
                </button>

                <button
                  onClick={() => handleClick("UserDetail")}
                  className={
                    activeComponent === "UserDetail" ? "active-link" : ""
                  }
                >
                  <i className="fa-solid fa-gear"></i> User Details
                </button>


                {status === 'ADMIN' && (
                  <>
                <button
                  onClick={() => handleClick("admin_config")}
                  className={
                    activeComponent === "admin_config" ? "active-link" : ""
                  }
                >
                  <i className="fa-solid fa-gear"></i> Org Config
                </button>

                <button
                  onClick={() => handleClick("user_list")}
                  className={
                    activeComponent === "user_list" ? "active-link" : ""
                  }
                >
                  <i className="fa-solid fa-gear"></i> All User
                </button>

                <button
                  onClick={() => handleClick("Cuser")}
                  className={activeComponent === "Cuser" ? "active-link" : ""}
                >
                  <i className="fa-solid fa-gear"></i> Create User
                </button>
                </>
                )}


              </div>
            )}
          </div> */}
          <button
            onClick={() => handleClick("UserDetail")}
            className="nav-link text-light text-start"
          >
            <i className="fa-solid fa-gear"></i> User Details
          </button>

          <button
            className="nav-link text-light text-start"
            onClick={handleLogout}
          >
            <i className="fas fa-sign-out-alt"></i> Logout
          </button>
        </nav>

        <div className="mt-auto px-3">
          <div className="d-flex align-items-center">
            <span className="ms-2">{email}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerticalNavBar;
