import React, { useRef } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AppProvider } from './context/AppContext';
import Home from './pages/home';
import Config from './pages/config';
import List from './pages/list';
import "bootstrap/dist/css/bootstrap.min.css";

// Create a context for socket if needed
const socketContext = React.createContext({});

function App() {
  const socket = useRef();

  return (
    <AppProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/*" element={<Home />} />
            <Route path="/config" element={<Config />} />
            <Route path="/list" element={<List />} />
          </Routes>
        </BrowserRouter>
    </AppProvider>
  );
}

export default App;
export { socketContext };
