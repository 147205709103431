import React, { useState, useRef, useEffect } from 'react';
import { useApi } from '../middleware/ApiMiddleware';

const FileUploadComponent = () => {
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [newFile, setNewFile] = useState(null);
    const [query, setQuery] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const api = useApi();
    const fileInputRef = useRef(null);

    useEffect(() => {
        const fetchFiles = async () => {
            try {
                const response = await api.get('api/process/files/user/');
                if (response.status === 200 && response.data.success) {
                    const files = response.data.data.file || [];
                    const formattedFiles = files.map(file => ({
                        id: file.file,
                        name: file.file_name,
                        users: file.users || [],
                    }));
                    setUploadedFiles(formattedFiles);
                }
            } catch (error) {
                console.error('Failed to fetch files', error);
            }
        };

        fetchFiles();
    }, []);

    const handleQueryChange = (e) => {
        setQuery(e.target.value);
        setErrorMessage('');
    };

    const handleQuerySubmit = async (e) => {
        if (e.key === 'Enter' && query.trim()) {
            try {
                const response = await api.get(`api/process/user/validation/?user_query=${query.trim()}`);
                if (response.status === 200 && response.data.success) {
                    const user = response.data.data;
                    if (!selectedUsers.some(u => u.org_user_id === user.org_user_id)) {
                        setSelectedUsers([...selectedUsers, user]);
                    }
                    setQuery(''); // Clear the input field
                } else {
                    setErrorMessage(response.data.error || 'User not found');
                }
            } catch (error) {
                console.error('Error validating user:', error);
                setErrorMessage('Failed to validate user');
            }
        }
    };

    const handleRemoveUser = (org_user_id) => {
        setSelectedUsers(selectedUsers.filter(user => user.org_user_id !== org_user_id));
    };

    const handleFileChange = (e) => {
        setNewFile(e.target.files[0]);
        setUploadSuccess(false);
    };

    const handleUpload = async () => {
        if (!newFile) {
            alert('Please select a file to upload.');
            return;
        }

        setUploading(true);
        const formData = new FormData();
        formData.append('file', newFile);
        if (selectedUsers.length !== 0) {
            const user_array = selectedUsers.map(user => user.org_user_id);
            user_array.forEach(user_id => {
                formData.append('user_ids', user_id);  
            });
        }
        
        try {
            const response = await api.post('api/process/files/user/', formData);
            if (response.status === 200) {
                const userData = JSON.parse(localStorage.getItem('user')) || {};
                const username = userData.username;
                const email = userData.email;
            
                const uploadedFile = {
                    id: response.data.file,
                    name: newFile.name,
                    users: [
                        {
                            username,
                            email,
                        },
                    ],
                };
                setUploadedFiles([uploadedFile, ...uploadedFiles]);
                setUploadSuccess(true);
            } else {
                const errorMessage = response.data.error || 'Failed to upload the file. Please try again.';
                alert(errorMessage);
            }
        } catch (error) {
            console.error('Upload failed', error);
        } finally {
            setUploading(false);
            setNewFile(null);
            fileInputRef.current.value = ''; // Reset file input
        }
    };

    const handleDelete = async (file_id) => {
        try {
            const response = await api.delete(`api/process/files/user/${file_id}/`);
            if (response.status === 200) {
                const updatedFiles = uploadedFiles.filter(file => file.id !== file_id);
                setUploadedFiles(updatedFiles);
            } else {
                alert('Failed to delete the file. Please try again.');
            }
        } catch (error) {
            console.error('Delete failed', error);
            alert('An error occurred while deleting the file.');
        }
    };

    return (
        <div className="file-upload-container">
            <div className="upload-box">
                <i className="fa-solid fa-upload upload-icon"></i>
                {/* <div className="dropdown">
                    <label htmlFor="user-select">Search User:</label>
                    <input
                        id="user-select"
                        type="text"
                        value={query}
                        onChange={handleQueryChange}
                        onKeyDown={handleQuerySubmit}
                        placeholder="Type and press Enter"
                    />
                    {errorMessage && <p className="error-message">{errorMessage}</p>}
                </div>
                <div className="selected-users">
                    <h5>Selected Users:</h5>
                    {selectedUsers.length > 0 ? (
                        <ul>
                            {selectedUsers.map(user => (
                                <li key={user.org_user_id}>
                                    {user.query}
                                    <button
                                        onClick={() => handleRemoveUser(user.org_user_id)}
                                        className="remove-btn"
                                    >
                                        Remove
                                    </button>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No users selected</p>
                    )}
                </div> */}
                <input
                    type="file"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    hidden
                />
                {!newFile && (
                    <button onClick={() => fileInputRef.current.click()} className="upload-btn">
                        Select File
                    </button>
                )}
                {newFile && (
                    <>
                        <p>Selected File: {newFile.name}</p>
                        <button
                            onClick={handleUpload}
                            disabled={uploading}
                            className="upload-btn"
                        >
                            {uploading ? 'Uploading...' : 'Upload'}
                        </button>
                    </>
                )}
                {uploadSuccess && (
                    <p className="upload-success-message">Upload complete! You can upload another file.</p>
                )}
                {uploadSuccess && setTimeout(() => setUploadSuccess(false), 5000)}
                <div className="file-format-info">
                    <span>PDF</span> <span>DOCX</span> <span>TXT</span> <span>&lt; 10 MB</span>
                </div>
            </div>

            <div className="uploaded-files-section">
                <h4>Uploaded Files</h4>
                {uploadedFiles.length === 0 ? (
                    <p>No files found</p>
                ) : (
                    <ul className="uploaded-files-list">
                        {uploadedFiles.map((file, index) => (
                            <li key={file.id || index} className="uploaded-file-item">
                                <i className="fa-solid fa-file-pdf pdf-icon"></i>
                                <div className="file-info">
                                    <p>{file.name}</p>
                                    <span>Users with Access:</span>
                                    <ul>
                                        {file.users.map((user, userIndex) => (
                                            <li key={userIndex}>
                                                {user.username} ({user.email})
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <button
                                    className="delete-btn"
                                    onClick={() => handleDelete(file.id)}
                                >
                                    <i className="fa-solid fa-trash delete-icon"></i>
                                </button>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default FileUploadComponent;
