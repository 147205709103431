import React, { useState } from 'react';
import { useApi } from '../middleware/ApiMiddleware';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';

const Signup = ({ changeComponent }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: ''
  });
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(''); // Error state
  const [success, setSuccess] = useState(''); // Success message
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const api = useApi();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    const payload = {
      name: formData.name,
      email: formData.email,
      password: formData.password,
      config: []
    };

    try {
      const response = await api.post('api/process/signup/user/v2/', payload, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (response.data.success) {
        localStorage.setItem("accessToken", response.data.data.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.data.data)); // Ensure data is stored as JSON string
        changeComponent('Home');
      } else {
        setError(response.data.message || 'Signup failed. Please try again.');
      }
    } catch (error) {
      setError('An error occurred during signup. Please try again.');
      console.error('Error during signup:', error);
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className="sign-container">
        <div className="form-box">
          <img 
            src={require('../assets/cplogo.jpg')} 
            className="form-image"
            alt="Logo"
          />
          <p>Create an account</p>
          <form onSubmit={handleSubmit}>
            {/* Name Field */}
            <div className="form-floating-group">
              <input
                type="text"
                id="name"
                name="name"
                className="form-floating-input"
                placeholder=""
                value={formData.name}
                onChange={handleChange}
                required
              />
              <label htmlFor="name" className="form-floating-label">
                Name
              </label>
            </div>

            {/* Email Field */}
            <div className="form-floating-group">
              <input
                type="email"
                id="email"
                name="email"
                className="form-floating-input"
                placeholder=""
                value={formData.email}
                onChange={handleChange}
                required
              />
              <label htmlFor="email" className="form-floating-label">
                Email Address
              </label>
            </div>

            {/* Password Field */}
            <div className="form-floating-group password-group">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                className="form-floating-input"
                placeholder=""
                value={formData.password}
                onChange={handleChange}
                required
              />
              <label htmlFor="password" className="form-floating-label">
                Password
              </label>
              <span 
                className="password-toggle-icon" 
                onClick={togglePasswordVisibility}
              >
                <i className={showPassword ? "fas fa-eye" : "fas fa-eye-slash"}></i>
              </span>
            </div>

            {/* Error and Success Messages */}
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {success && <p style={{ color: 'green' }}>{success}</p>}

            {/* Submit Button */}
            <button type="submit" disabled={loading}>
              {loading ? 'Signing Up...' : 'Sign Up'}
            </button>
          </form>
          <p>Already have an account?</p>
          <button onClick={() => changeComponent('login')}>
            Login
          </button>
        </div>
      </div>
    </>
  );
};

export default Signup;
