import React, { useState, useEffect, useRef } from "react";
import { useApi } from "../middleware/ApiMiddleware";

const Header = ({ changeComponent }) => {
  const [showPopup, setShowPopup] = useState(false);
  const api = useApi();
  const popupRef = useRef(null);

  // Toggle the popup visibility
  const togglePopup = (e) => {
    e.stopPropagation(); // Prevent this click from propagating to the document listener
    setShowPopup((prev) => !prev);
  };

  // Close popup
  const closePopup = () => {
    setShowPopup(false);
  };

  // Handle clicks outside the popup
  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      closePopup();
    }
  };

  // Add event listener for clicks outside the popup
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleLogout = async () => {
    try {
      await api.get("api/process/logout/v2/");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");
      localStorage.removeItem("association");
      changeComponent("login");
    } catch (error) {
      console.error("Error Logging Out:", error);
    }
  };

 return (
  <div
    className="header d-flex justify-content-between align-items-center p-1"
    style={{ backgroundColor: "#d9e8ff" }} // Light blue shade
  >
    {/* Logo and Alaap */}
    <div className="d-flex align-items-center">
      <div className="hamburger-menu" style={{ width: "50px" }}>
      </div>
      <div
        className="logo-container d-flex align-items-center cursor-pointer ms-2"
        onClick={() => changeComponent("Home")}
      >
        <img
          src={require("../assets/cplogo.jpg")}
          alt="Alaap Logo"
          className="logo-image me-2"
          style={{ height: "40px", width: "auto" }} // Adjust logo size as needed
        />
        <span className="logo-text fs-10 fw-bold text-primary ms-2">Alaap</span>
      </div>
    </div>

    {/* Profile Section */}
    <div className="header__right position-relative">
      <i
        className="fa-solid fa-user fs-4 text-dark"
        style={{ cursor: "pointer" }}
        onClick={togglePopup} // Toggle popup when clicked
      ></i>

      {showPopup && (
        <div
          className="avatar-popup position-absolute bg-light border rounded p-2"
          ref={popupRef}
          style={{
            right: 0,
            top: "40px",
            zIndex: 1000,
            width: "150px",
            boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
          }}
        >
          <div
            className="popup-item py-2 px-3 text-dark"
            style={{ cursor: "pointer" }}
            onClick={() => {
              changeComponent("UserDetail");
              closePopup();
            }}
          >
            View Profile
          </div>
          <div
            className="popup-item py-2 px-3 text-dark"
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleLogout();
              closePopup();
            }}
          >
            Logout
          </div>
        </div>
      )}
    </div>
  </div>
);
}
export default Header;
