import React from 'react';
import { useNavigate } from 'react-router-dom';

const HeaderHome = ({ changeComponent, activeComponent }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem('accessToken'); // Check for token in localStorage

return (
  <div
    className="header d-flex justify-content-between align-items-center p-1"
    style={{ backgroundColor: "#d9e8ff" }} // Light blue shade
  >

    <div className="d-flex align-items-center">
      <div className="hamburger-menu" style={{ width: "50px" }}>
      </div>

      {/* Light side: Logo and Alaap */}
      <div
        className="logo-container d-flex align-items-center cursor-pointer ms-2"
        onClick={() => changeComponent("Home")}
      >
        <img
          src={require("../assets/cplogo.jpg")}
          alt="Alaap Logo"
          className="logo-image me-2"
          style={{ height: "40px", width: "auto" }} // Adjust logo size as needed
        />
        <span className="logo-text fs-10 fw-bold text-primary ms-3">Alaap</span>
      </div>
    </div>

    {/* Right side: Sign In Button */}
    <div className="header__right me-3">
      {activeComponent === "Home" && !token && (
        <button
          onClick={() => changeComponent("login")}
          className="btn btn-primary"
        >
          Sign In
        </button>
      )}
    </div>
  </div>
);

};

export default HeaderHome;
