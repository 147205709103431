import React, { useState } from 'react';
import { useApi } from '../middleware/ApiMiddleware'; // Import your API middleware
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';

const Signup = ({ changeComponent }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    username: '',
    email: '',
    phone: '',
    password: ''
  });
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(''); // Error state
  const [success, setSuccess] = useState(''); // Success message
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const api = useApi();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    const payload = {
      user: {
        name: formData.name,
        email: formData.email,
        username: formData.username || null,
        phone: formData.phone || null,
        // password: formData.password,
      },
      config: []
    };

    try {
      const response = await api.post('api/process/config/profile/user/', payload, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (response.data.success) {
        // localStorage.setItem("accessToken", response.data.data.data.token);
        // localStorage.setItem("user", JSON.stringify(response.data.data.data)); // Ensure data is stored as JSON string
        // changeComponent('Home');
        setSuccess("User Created")
      } else {
        setError(response.data.message || 'User Creation failed. Please try again.');
      }
    } catch (error) {
      setError('An error occurred during User Creation. Please try again.');
      console.error('Error during User Creation:', error);
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    // console.log("change");
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className="sign-container">
        <div className="form-box">
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              id="username"
              name="username"
              placeholder="Your username"
              value={formData.username}
              onChange={handleChange}
              required
            />

            <input
              type="email"
              id="email"
              name="email"
              placeholder="Your email address"
              value={formData.email}
              onChange={handleChange}
              required
            />

            <input
              type="text"
              id="phone"
              name="phone"
              placeholder="Your phone number"
              value={formData.phone}
              onChange={handleChange}
              required
            />
{/* 
            <div className="password-container">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                placeholder="Pick a password"
                value={formData.password}
                onChange={handleChange}
                required
              />
              <span 
                className="password-toggle-icon" 
                onClick={togglePasswordVisibility}
              >
                <i className={showPassword ? "fas fa-eye" : "fas fa-eye-slash"}></i>
              </span>
            </div> */}

            {error && <p style={{ color: 'red' }}>{error}</p>}
            {success && <p style={{ color: 'green' }}>{success}</p>}

            <button type="submit" disabled={loading}>
              {loading ? 'Creating...' : 'Create User'}
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default Signup;
