import React, { useState, useEffect } from 'react';
import { useApi } from '../middleware/ApiMiddleware';
import UserData from './UserData';

const AllUserComponent = () => {
    const [users, setUsers] = useState([]); // Renamed from uploadedFiles to users
    const [loading, setLoading] = useState(false); // Changed from uploading to loading
    const [noUsersFound, setNoUsersFound] = useState(false); // Renamed noFilesFound to noUsersFound
    const [selectedUser, setSelectedUser] = useState(null); // State for selected user
    const api = useApi();

    useEffect(() => {
        const fetchUsers = async () => {
            setLoading(true); // Indicate loading
            try {
                const response = await api.get('api/process/config/profile/list/');
                if (response.status === 200 && response.data.success) {
                    const usersList = response.data.data || [];
                    if (usersList.length > 0) {
                        const formattedUsers = usersList.map(user => ({
                            org_user_id: user.org_user_id,
                            first_name: user.first_name,
                            last_name: user.last_name,
                            email: user.email,
                            is_active: user.is_active,
                        }));
                        setUsers(formattedUsers);
                        setNoUsersFound(false);
                    } else {
                        setNoUsersFound(true);
                    }
                } else {
                    setNoUsersFound(true);
                }
            } catch (error) {
                console.error("Failed to fetch users", error);
                setNoUsersFound(true);
            } finally {
                setLoading(false); // Stop loading
            }
        };

        fetchUsers();
    }, []);

    const handleToggleStatus = async (org_user_id, newStatus) => {
        try {
            const payload = {
                is_active: newStatus,
                org_users: [org_user_id],
            };
            const response = await api.patch(`api/process/config/profile/list/`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                setUsers(prevUsers =>
                    prevUsers.map(user =>
                        user.org_user_id === org_user_id
                            ? { ...user, is_active: newStatus }
                            : user
                    )
                );
            } else {
                console.error("Failed to update user status");
            }
        } catch (error) {
            console.error("Error updating user status", error);
        }
    };

    const handleUserClick = (userId) => {
        setSelectedUser(userId); // Set selected user
    };

    const handleBackClick = () => {
        setSelectedUser(null); // Reset selected user to go back to the list
    };

    if (selectedUser) {
        return (
            <div>
                <UserData userId={selectedUser} />
                <button onClick={handleBackClick} className="back-button">
                    Back to User List
                </button>
            </div>
        );
    }

    return (
        <div className="file-upload-container">
            <div className="uploaded-files-section">
                <h4>All Users</h4>
                {loading ? (
                    <p>Loading users...</p>
                ) : noUsersFound ? (
                    <p>No users found</p>
                ) : (
                    <ul className="uploaded-files-list">
                        {users.map((user, index) => (
                            <li key={user.org_user_id || index} className="uploaded-file-item">
                                <i className="fa-solid fa-user-circle user-icon"></i>
                                <div
                                    className="file-info"
                                    onClick={() => handleUserClick(user.org_user_id)} // Handle click
                                >
                                    <p><strong>Org User ID:</strong> {user.org_user_id}</p>
                                    <p><strong>Name:</strong> {user.first_name} {user.last_name}</p>
                                    <p><strong>Email:</strong> {user.email}</p>
                                    <p><strong>Status:</strong> {user.is_active ? "Active" : "Inactive"}</p>
                                </div>
                                <button
                                    className={`delete-btn ${user.is_active ? "active" : "inactive"}`}
                                    onClick={() => handleToggleStatus(user.org_user_id, !user.is_active)}
                                >
                                    <i className={`fa-solid ${user.is_active ? "fa-user-check" : "fa-user-slash"}`}></i>
                                </button>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default AllUserComponent;
