import React, { useState, useEffect, useRef } from "react";
import { useApi } from "../middleware/ApiMiddleware";

const FeatureSelector = ({ onFeatureSelect }) => {
  const api = useApi();
  const [features, setFeatures] = useState([]);
  const [noFeaturesFound, setNoFeaturesFound] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [configOptions, setConfigOptions] = useState([]);
  const [formData, setFormData] = useState({});
  const [association, setAssociation] = useState(null);
  const [lastUsedFeature, setLastUsed] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  const fetchFeatures = async () => {
    try {
      const response = await api.get("api/process/feature/association/user/");
      if (response.status === 200 && response.data.success) {
        const featureData = response.data.data || [];
        const formattedFeatures = featureData.map((feature) => ({
          id: feature.id,
          featureName: feature.feature_name,
          feature_id: feature.feature,
        }));
        setFeatures(formattedFeatures);
        setNoFeaturesFound(featureData.length === 0);
      } else {
        setNoFeaturesFound(true);
      }
    } catch (error) {
      console.error("Failed to fetch features", error);
      setNoFeaturesFound(true);
    }
  };

  const fetchConfigOptions = async (id) => {
    try {
      const response = await api.get(`api/process/servicesettingV2/?association=${id}`);
      if (response.status === 200 && response.data.success) {
        const options = response.data.data.config_options || [];
        setConfigOptions(options);

        // Initialize form data with default values
        const initialFormData = {};
        options.forEach((config) => {
          if (config.input_type === "checkbox") {
            initialFormData[config.config_code] = config.default === "True";
          } else {
            initialFormData[config.config_code] = config.default || "";
          }
        });
        setFormData(initialFormData);
      } else {
        setConfigOptions([]);
      }
    } catch (error) {
      console.error("Failed to fetch config options", error);
      setConfigOptions([]);
    }
  };

  const handleFeatureChange = (e) => {
    const selectedId = e.target.value;
    const selectedFeatureObj = features.find((feature) => feature.id === parseInt(selectedId));

    setSelectedFeature(selectedId);
    setAssociation(selectedId);
    onFeatureSelect(selectedId);

    // Save both id and name to localStorage
    localStorage.setItem("association", JSON.stringify({
      id: selectedId,
      name: selectedFeatureObj.featureName,
    }));

    fetchConfigOptions(selectedId);
    // setDropdownVisible(false); // Close dropdown on feature select
  };

  const handleInputChange = (configCode, value) => {
    setFormData((prev) => ({
      ...prev,
      [configCode]: value,
    }));
  };

  const handleSubmit = async () => {
    if (!selectedFeature) {
      alert("Please select a feature before submitting.");
      return;
    }

    const changes = configOptions.map((config) => {
      const value = formData[config.config_code];
      const formattedValue =
        config.input_type === "text" && !isNaN(value) ? parseInt(value, 10) : value;

      return {
        config_code: config.config_code,
        input_type: config.input_type === "dropdown" ? "drop_down" : config.input_type,
        value: formattedValue,
      };
    });

    const payload = {
      association: parseInt(selectedFeature, 10),
      changes: changes,
    };
    // console.log(payload);

    try {
      const response = await api.post(
        `api/process/feature/association/user/`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        alert("Configuration updated successfully!");
        setAssociation(response.data.data.association);
        const currentAssociation = JSON.parse(localStorage.getItem("association")) || {};

        // Update the ID with the response data
        const updatedAssociation = {
          ...currentAssociation,
          id: response.data.data.association,
          name: currentAssociation.name, // Ensure this stays updated
        };
        fetchFeatures();
        // Save the updated association back to localStorage
        localStorage.setItem("association", JSON.stringify(updatedAssociation));
      } else {
        alert("Failed to update configuration.");
      }
    } catch (error) {
      console.error("Failed to submit configuration", error);
      alert("An error occurred while submitting the configuration.");
    }
  };

  const handleOutsideClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    const initializeLastUsedFeature = async () => {
      // Fetch features first
      if (features.length === 0) {
        await fetchFeatures();
      }

      // Check and set the last used feature from localStorage
      const lastUsed = JSON.parse(localStorage.getItem("association")) || {};

      if (lastUsed.id) {
        setLastUsed(lastUsed);
        setSelectedFeature(lastUsed.id);
        setAssociation(lastUsed.id);
        fetchConfigOptions(lastUsed.id);
        onFeatureSelect(lastUsed.id);
      } else {
        // If no association is found, set feature with feature_id = 1 as selected
        const defaultFeature = features.find(feature => feature.feature_id === 1);
        if (defaultFeature) {
          setSelectedFeature(defaultFeature.id);
          setAssociation(defaultFeature.id);
          localStorage.setItem("association", JSON.stringify({
            id: defaultFeature.id,
            name: defaultFeature.featureName
          }));
          fetchConfigOptions(defaultFeature.id);
          onFeatureSelect(defaultFeature.id);
        }
      }
    };

    initializeLastUsedFeature();

    // Add event listener to close dropdown when clicking outside
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [features, association]);

  useEffect(() => {
    if (selectedFeature === undefined) {
      // If selectedFeature is undefined, try to look it up in the features array
      const feature = features.find((feature) => feature.id === selectedFeature);
      if (feature) {
        setSelectedFeature(feature.id); // Update selected feature with correct id
      }
    }
  },);

  // Dynamically render the label for the button
  const getButtonLabel = () => {
    const lastUsed = JSON.parse(localStorage.getItem("association"));
    if (lastUsed && lastUsed.name) {
      return lastUsed.name;
    }
    return "Select a Feature";
  };

  return (
    <div style={{ display: "flex", justifyContent: "flex-end", marginRight: "20px" }}>
      {noFeaturesFound ? (
        <p>No features found.</p>
      ) : (
        <>
          <button
            onClick={() => setDropdownVisible(!dropdownVisible)}
            style={{ padding: "10px", cursor: "pointer" }}
          >
            {getButtonLabel()}
          </button>
          {dropdownVisible && (
            <div
              ref={dropdownRef}
              style={{
                position: "absolute",
                backgroundColor: "white",
                border: "1px solid #ccc",
                boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                zIndex: 1,
                padding: "10px",
                marginTop: "10px",
                width: "300px",
              }}
            >
              <select
                onChange={handleFeatureChange}
                value={selectedFeature || ""}
                style={{ width: "100%", padding: "8px", marginBottom: "10px" }}
              >
                {/* <option value="">Select a feature</option> */}
                {features.map((feature) => (
                  <option key={feature.id} value={feature.id}>
                    {feature.featureName}
                  </option>
                ))}
              </select>
              {selectedFeature && configOptions.length > 0 && (
                <div style={{ marginTop: "10px" }}>
                  {/* <h2>Configuration Options</h2> */}
                  {configOptions.map((config) => (
                    <div key={config.config_code} style={{ marginBottom: "1em" }}>
                      <label htmlFor={config.config_code}>
                        {config.display_name}:
                      </label>
                      {config.input_type === "dropdown" && (
                        <select
                          id={config.config_code}
                          value={formData[config.config_code] || ""}
                          onChange={(e) =>
                            handleInputChange(config.config_code, e.target.value)
                          }
                        >
                          {/* <option value="">Select an option</option> */}
                          {config.common_names.map((name, index) => (
                            <option key={index} value={name}>
                              {name}
                            </option>
                          ))}
                        </select>
                      )}
                      {config.input_type === "text" && (
                        <input
                          type="text"
                          id={config.config_code}
                          value={formData[config.config_code] || ""}
                          onChange={(e) =>
                            handleInputChange(config.config_code, e.target.value)
                          }
                        />
                      )}
                      {config.input_type === "checkbox" && (
                        <input
                          type="checkbox"
                          id={config.config_code}
                          checked={formData[config.config_code] || false}
                          onChange={(e) =>
                            handleInputChange(config.config_code, e.target.checked)
                          }
                        />
                      )}
                    </div>
                  ))}
                  <button onClick={handleSubmit}>Submit</button>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default FeatureSelector;

