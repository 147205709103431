import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../context/AppContext';
import Home from '../components/Home';
import HeaderHome from '../components/HeaderHome';
import LoginForm from '../components/LoginForm';
import Signup from '../components/Signup';
import VerticalNavBar from '../components/Menubar';
import Header from '../components/Header';
import VoiceBot from '../components/VoiceBot';
import File from '../components/File';
import ConfigComponent from "../components/ConfigComponent"
import AllUser from "../components/AllUser"
import Footer from "../components/Footer"
import UserData from "../components/UserData";
import Cuser from "../components/Cuser";

// Helper function to parse cookies
const getCookie = (name) => {
  const cookies = document.cookie.split('; ').reduce((acc, cookie) => {
    const [key, value] = cookie.split('=');
    acc[key] = decodeURIComponent(value);
    return acc;
  }, {});
  return cookies[name] || null;
};

export default function HomePage() {
  const { activeComponent, changeComponent } = useContext(AppContext);
  const [userId, setUserId] = useState('0000'); // Default value for userId

  let token = localStorage.getItem('accessToken') || getCookie('accessToken');
  let userData = localStorage.getItem('user') || getCookie('user');

  useEffect(() => {
    if (userData) {
      try {
        const parsedUser = JSON.parse(userData);
        setUserId(parsedUser.id || '0000'); // Update state with user ID
      } catch (error) {
        console.error('Error parsing userData:', error);
      }
    }
  }, [userData]); // Run effect when userData changes

  return (
    <>
      {!token && (
        <>
          <HeaderHome changeComponent={changeComponent} activeComponent={activeComponent} />
          {activeComponent === 'Home' && <Home changeComponent={changeComponent} />}
          {activeComponent === 'login' && <LoginForm changeComponent={changeComponent} />}
          {activeComponent === 'signup' && <Signup changeComponent={changeComponent} />}
          <Footer />
        </>
      )}
      {token && (
        <div className="app-container">
          <VerticalNavBar activeComponent={activeComponent} changeComponent={changeComponent} />
          <div className="content">
            <Header changeComponent={changeComponent} />
            <div className='main_page'>
            {activeComponent === 'Home' && <Home changeComponent={changeComponent} token={token} />}
            {activeComponent === 'Dashboard' && <VoiceBot changeComponent={changeComponent} />}
            {activeComponent === 'Config' && <ConfigComponent />}
            {activeComponent === 'admin_config' && <ConfigComponent status="ADMIN" />}
            {activeComponent === 'user_list' && <AllUser />}
            {activeComponent === 'UserDetail' && <UserData userId={userId} />}
            {activeComponent === 'Cuser' && <Cuser />}
            {activeComponent === 'File' && <File changeComponent={changeComponent} />}
            </div>
            <Footer />
          </div>
        </div>
      )}
    </>
  );
}
