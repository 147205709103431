import React, { useState, useEffect } from 'react';
import { GoogleGenerativeAI } from "@google/generative-ai";

const HindiSpeechRecognition = () => {
  const [transcript, setTranscript] = useState('');
  const [response, setResponse] = useState('');
  const [recognition, setRecognition] = useState(null);
  const genAI = new GoogleGenerativeAI("AIzaSyDmVkUrKZMOfAqy_hq1A1Qv7mrbNzYBdL8"); // Consider moving this to an environment variable.

  useEffect(() => {
    // Initialize SpeechRecognition
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (SpeechRecognition) {
      const recog = new SpeechRecognition();
      recog.continuous = true;
      recog.lang = 'en';

      recog.onresult = (event) => {
        const current = event.resultIndex;
        const newTranscript = event.results[current][0].transcript;
        setTranscript((prev) => prev + ' ' + newTranscript);
      };

      recog.onerror = (event) => {
        console.error('Speech recognition error:', event.error);
      };

      setRecognition(recog);

      return () => {
        recog.stop();
        recog.onresult = null; // Cleanup on unmount
        recog.onerror = null; // Cleanup on unmount
      };
    } else {
      console.error('SpeechRecognition API is not supported in this browser');
    }
  }, []);

  const startListening = () => recognition && recognition.start();
  const stopListening = () => recognition && recognition.stop();

  const handleSubmit = async () => {
    if (transcript.trim()) {
      const prompt = `
      Extract the following fields from the given query and return them in valid JSON format. Omit any fields not explicitly mentioned in the query:

      name: Extract the person's name. If multiple names are provided, take the last valid one. If no valid name exists, omit the field.
      email: Extract and normalize the email address. Combine fragmented parts, stritly remove spaces, and ensure the email is valid.replace underscore with "_"
      address: Extract the full address as stated in the query. If not provided don't include in json
      city: Validate the city against the predefined list. If it doesn't match, leave the field blank. include city field in the json if address field is present in the json don't compare state with city.
  
      Predefined Cities List:
          New York
          Los Angeles
          Chicago
          Houston
          Miami
          Gurgaon
          Bangalore
          Mangalore

      Query Example:
      "My email ID is first_word second_word .... nth_word@domain.com"

      Expected JSON Output:

      {
        "email": "first_wordsecond_word....nth_word@domain.com",
      }
      ${transcript}`
      try {
        const model = genAI.getGenerativeModel({ model: "gemini-pro" });
        const result = await model.generateContent(prompt);
        
        // Check if the response is structured correctly
        if (result && result.response) {
          setResponse(result.response.text || "No text found in response.");
        } else {
          setResponse("Unexpected response format.");
        }
      } catch (error) {
        console.error('Error fetching response from Ola Kutrim API:', error);
      }
    }
  };

  if (!recognition) {
    return <p>Your browser does not support the SpeechRecognition API.</p>;
  }

  return (
    <div>
      <button onClick={startListening}>Start Listening (Hindi)</button>
      <button onClick={stopListening}>Stop Listening</button>
      <button onClick={handleSubmit}>Submit to Gemini</button>
      <p>Transcript: {transcript}</p>
      <p>Response: {response}</p>
    </div>
  );
};

export default HindiSpeechRecognition;