import React, { useState, useEffect } from 'react';
import { useApi } from '../middleware/ApiMiddleware';
import { useNavigate } from 'react-router-dom';

const UserData = ({ userId }) => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        username: '',
        email: '',
        phone: '',
        password: '',
    });
    const [originalData, setOriginalData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const api = useApi();
    const [loginuserId, setLoginUserId] = useState('0000');

    const userData = localStorage.getItem('user');

    useEffect(() => {
        if (userData) {
            const parsedUser = JSON.parse(userData);
            setLoginUserId(parsedUser.id || '0000');
        }
    }, [userData]);

    useEffect(() => {
        const fetchUserData = async () => {
            setFetching(true);
            setError('');

            try {
                const response = await api.get('api/process/config/v2/profile/user/');
                if (response.data.success) {
                    const userDataApi = response.data.data[0];
                    setOriginalData(userDataApi);
                    setFormData({
                        name: `${userDataApi.first_name} ${userDataApi.last_name}`.trim(),
                        email: userDataApi.email || '',
                        phone: userDataApi.phone || '',
                        username: userDataApi.username || '',
                        password: '',
                    });
                } else {
                    setError('Failed to fetch user data.');
                }
            } catch (err) {
                console.error('Error fetching user data:', err);
                setError('An error occurred while fetching user data.');
            } finally {
                setFetching(false);
            }
        };

        fetchUserData();
    }, []);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setSuccess('');

        const payload = {};

        if (formData.name !== `${originalData.first_name} ${originalData.last_name}`.trim()) {
            payload.name = formData.name;
        }

        if (formData.email !== originalData.email) {
            payload.email = formData.email;
        }

        if (formData.username !== originalData.username) {
            payload.username = formData.username;
        }

        if (formData.phone !== originalData.phone) {
            payload.phone = formData.phone;
        }

        if (formData.password) {
            payload.password = formData.password;
        }

        // console.log('Payload:', payload);

        try {
            const response = await api.patch(`api/process/config/v2/profile/user/${userId}/`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.data.success) {
                setSuccess('Profile updated successfully.');
                localStorage.setItem('user', JSON.stringify(response.data.data));
            } else {
                setError(response.data.message || 'Update failed. Please try again.');
            }
        } catch (err) {
            console.error('Error during update:', err);
            setError('An error occurred during the update. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="sign-container">
            <div className="form-box">
                {fetching ? (
                    <p>Loading user data...</p>
                ) : (
                    <form onSubmit={handleSubmit}>
                        {[
                            { label: 'Name', name: 'name', type: 'text', value: formData.name },
                            { label: 'Username', name: 'username', type: 'text', value: formData.username },
                            { label: 'Email Address', name: 'email', type: 'email', value: formData.email },
                            { label: 'Phone', name: 'phone', type: 'text', value: formData.phone },
                        ].map(({ label, name, type, value }) => (
                            <div key={name} className="form-floating-group">
                                <input
                                    type={type}
                                    id={name}
                                    name={name}
                                    className="form-floating-input"
                                    value={value}
                                    onChange={handleChange}
                                    placeholder=""
                                    required
                                />
                                <label htmlFor={name} className="form-floating-label">
                                    {label}
                                </label>
                            </div>
                        ))}

                        {userId === loginuserId && (
                            <div className="form-floating-group password-group">
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    name="password"
                                    className="form-floating-input"
                                    value={formData.password}
                                    onChange={handleChange}
                                    placeholder=""
                                />
                                <label htmlFor="password" className="form-floating-label">
                                    Password
                                </label>
                                <span
                                    className="password-toggle-icon"
                                    onClick={togglePasswordVisibility}
                                >
                                    <i className={showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'}></i>
                                </span>
                            </div>
                        )}

                        {error && <p style={{ color: 'red' }}>{error}</p>}
                        {success && <p style={{ color: 'green' }}>{success}</p>}
                        <button type="submit" disabled={loading}>
                            {loading ? 'Updating...' : 'Update'}
                        </button>
                    </form>
                )}
            </div>
        </div>
    );
};

export default UserData;
