import React from 'react';
import Footer from './Footer';

const PromoSection = ({ changeComponent, token }) => {
// Handle button click to navigate based on token presence
const handleButtonClick = () => {
  if (token) {
    changeComponent('Dashboard');  // Redirect to dashboard if token is present
  } else {
    changeComponent('signup');  // Redirect to signup if no token is found
  }
};

return (
  <div
    className="sign-container d-flex flex-column align-items-center justify-content-center text-center"
    style={{
      height: "100vh", // Ensure it fits exactly in the viewport
      minWidth: "360px", // Responsive width
      overflowY: "hidden", // Prevent scrolling
    }}
  >
    {/* Image Block */}
    <div className="mb-3">
      <img
        src={require("../assets/image_15.png")}
        alt="Driving promo"
        className="img-fluid"
        style={{ maxWidth: "400px" }}
      />
    </div>

    {/* Text Block */}
    <div>
      <p className="fs-4 text-secondary mb-2">Ask Alaap</p>
      <h2 className="fw-bold mb-4">Your AI Friend</h2>
      <button className="btn btn-primary btn-lg" onClick={handleButtonClick}>
        Try It Now
      </button>
    </div>
  </div>
);
};


export default PromoSection;
