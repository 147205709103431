// Footer.js
import React from 'react';

const Footer = () => {
  return (
    <footer
      className="position-fixed bottom-0 w-100 text-center text-dark py-2"
      style={{ backgroundColor: "#d9e8ff" }}
    >
      <p className="mb-0">
        Powered by{" "}
        <a
          href="https://codepackers.com"
          target="_blank"
          rel="noopener noreferrer"
          className="text-decoration-none text-primary fw-bold"
        >
          Codepackers
        </a>
      </p>
    </footer>
  );
};

export default Footer;
