import React, { useEffect, useState } from 'react';
import { useApi } from '../middleware/ApiMiddleware';

const ConfigComponent = ({ status = 'USER' }) => {
  const [formData, setFormData] = useState({});
  const [settingsData, setSettingsData] = useState([]);
  const [services, setServices] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user_config, setUserConfig] = useState(null);
  const [org_config, setOrgConfig] = useState(null);
  const api = useApi();

  useEffect(() => {
    const fetchConfigData = async () => {
      setLoading(true);
      try {
        const response = await api.get('api/process/servicesetting/');
        if (response.data.success) {
          setSettingsData(response.data.data.services_form);
          setServices(response.data.data.services);
          setLanguages(response.data.data.language);
          setUserConfig(response.data.data.current_config);
          setOrgConfig(response.data.data.org_config);
          prefillFormData( status === 'ADMIN' ? response.data.data.org_config : response.data.data.current_config);
          
        } else {
          setError('Failed to load settings');
        }
      } catch (err) {
        console.error('Error fetching config data:', err);
        setError('An error occurred while fetching settings');
      } finally {
        setLoading(false);
      }
    };

    fetchConfigData();
  }, []);

  const prefillFormData = (config) => {
    const initialData = config.reduce((acc, item) => {
      acc[item.name] = { ...item };
      return acc;
    }, {});
    setFormData(initialData);
  };

  const handleChange = (sectionName, sectionService, field, value) => {
    setFormData((prev) => ({
      ...prev,
      [sectionService]: {
        ...prev[sectionService],
        [field]: field === 'source_text' ? (value ? "1" : "0") : value,
      },
    }));
  };

  const getServiceOptions = (serviceFormId) => services.filter((service) => service.service_form_id === serviceFormId);

  const getLanguageOptions = (serviceId) => languages.filter((language) => language.service_id === serviceId);

  const renderInputField = (sectionName, sectionId, sectionService, param) => {
    switch (param.type) {
      case 'drop_box':
        if (param.db_name === 'service') {
          const sectionSettings = settingsData.find((s) => s.id === sectionId);
          const relevantServices = sectionSettings ? getServiceOptions(sectionSettings.id) : [];

          return (
            <select
              key={param.db_name}
              value={formData[sectionService]?.service || ''}
              onChange={(e) => {
                const selectedValue = e.target.value;
                handleChange(sectionName, sectionService, param.db_name, selectedValue);
                setFormData((prev) => ({
                  ...prev,
                  [sectionService]: {
                    ...prev[sectionService],
                    dest_lang: '',
                  },
                }));
              }}
            >
              <option value="">Select {param.display_name}</option>
              {relevantServices.map((service) => (
                <option key={service.ID} value={service.service_code}>
                  {service.name}
                </option>
              ))}
            </select>
          );
        }

        if (param.db_name === 'dest_lang') {
          const selectedService = formData[sectionService]?.service;
          const relevantLanguages = selectedService
            ? getLanguageOptions(
                services.find((s) => s.service_code === selectedService)?.ID
              )
            : [];

          return (
            <select
              key={param.db_name}
              value={formData[sectionService]?.dest_lang || ''}
              onChange={(e) => handleChange(sectionName, sectionService, param.db_name, e.target.value)}
            >
              <option value="">Select {param.display_name}</option>
              {relevantLanguages.map((lang) => (
                <option key={lang.lng_code} value={lang.lng_code}>
                  {lang.name}
                </option>
              ))}
            </select>
          );
        }
        break;
      case 'text_box':
        return (
          <input
            key={param.db_name}
            type="text"
            placeholder={param.display_name}
            value={formData[sectionService]?.[param.db_name] || ''}
            onChange={(e) => handleChange(sectionName, sectionService, param.db_name, e.target.value)}
          />
        );
      case 'check_box':
        return (
          <input
            key={param.db_name}
            type="checkbox"
            checked={formData[sectionService]?.[param.db_name] === "1"}
            onChange={(e) => handleChange(sectionName, sectionService, param.db_name, e.target.checked)}
          />
        );
      default:
        return null;
    }
  };

  const createPayload = (formData) => {
    const configData = status === 'ADMIN' ? org_config : user_config;
    const defaults = configData?.reduce((acc, config) => {
      acc[config.name] = config;
      return acc;
    }, {});

    const payload = [
      {
        name: 'ASR',
        service: formData.ASR?.service || defaults?.ASR?.service,
        dest_lang: formData.ASR?.dest_lang || defaults?.ASR?.dest_lang,
        source_lang: formData.ASR?.source_lang || defaults?.ASR?.source_lang,
      },
      {
        name: 'LLM',
        query: formData.LLM?.query || defaults?.LLM?.query,
        gender: formData.LLM?.gender || defaults?.LLM?.gender,
        service: formData.LLM?.service || defaults?.LLM?.service,
        dest_lang: formData.LLM?.dest_lang || defaults?.LLM?.dest_lang || 'Hinglish',
        directnes: formData.LLM?.directnes || defaults?.LLM?.directnes,
        char_limit: parseInt(formData.LLM?.char_limit || defaults?.LLM?.char_limit, 10),
        source_text: parseInt(formData.LLM?.source_text || defaults?.LLM?.source_text || "0"),
      },
      {
        name: 'TTS',
        query: formData.TTS?.query || defaults?.TTS?.query,
        service: formData.TTS?.service || defaults?.TTS?.service,
        dest_lang: formData.TTS?.dest_lang || defaults?.TTS?.dest_lang,
        source_lang: formData.TTS?.source_lang || defaults?.TTS?.source_lang,
      },
    ];

    return status === 'ADMIN' ? { org_config: payload } : { config: payload };
  };

  const handleSave = async () => {
    setLoading(true);
    setError(null);
    const payload = createPayload(formData);

    try {
      const response = await api.patch('api/process/profile/', payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.data.success) {
        // console.log('Form data saved successfully:', response.data);
      } else {
        setError('Failed to save settings');
      }
    } catch (error) {
      console.error('API error:', error);
      setError('An error occurred while saving the settings');
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="error-message">{error}</div>;

  return (
    <div className="form-container">
      {settingsData.map((section) => (
        <div key={section.ordering} className="settings-section">
          <h3>{section.name}</h3>
          {section.parameter.map((param) => (
            <div key={param.db_name} className="form-group">
              <label>{param.display_name}:</label>
              {renderInputField(section.name,section.id, section.Service, param)}
            </div>
          ))}
        </div>
      ))}
      <div className="save-button">
        <button onClick={handleSave} disabled={loading}>
          {loading ? 'Saving...' : 'Save'}
        </button>
      </div>
    </div>
  );
};

export default ConfigComponent;
